export default class UTMs {
	#utms = null;
	#urlParams = null;
	#referralDomain = null;
	#utmz = null;
	#organicSource = null;

	#getCookie(name) {
		name = name + "=";
		const start = document.cookie.indexOf(name);
		if (start == -1) return false;
		let end = document.cookie.indexOf(";", start);
		end == -1 && (end = document.cookie.length);
		return document.cookie.substring(start + name.length, end);
	}

	#setCookie(value) {
		this.#clearExternal();
		const d = new Date();
		d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
		const expires = "expires=" + d.toUTCString();
		value = encodeURIComponent(JSON.stringify(value));
		document.cookie = "_source_information=" + value + ";" + expires + ";path=/";
	}

	#isDirect() {
		if (document.referrer == "") return true;
		return false;
	}

	#matchReferrer(search_pattern) {
		const results = document.referrer.match(search_pattern);
		if (results === null) return false;
		return results.filter((result) => result !== undefined).pop();
	}

	#getOrganicSource() {
		if (this.#organicSource === null) {
			const searchPattern = /(www\.(google)\.com|www\.(bing)\.com|www\.(yahoo)\.com|(duckduckgo)\.com|www\.(ecosia\.org)|www\.(aol)\.com|www\.(sogou)\.com|www\.(qwant)\.com|(yandex)\.com)/i;
			this.#organicSource = this.#matchReferrer(searchPattern);
		}
		return this.#organicSource;
	}

	#isReferral() {
		if (this.#isDirect()) return false;
		if (document.referrer.match(/^https?:\/\/www\.nathab\.com/)) return false;
		return true;
	}

	#getURLParameters() {
		if (this.#urlParams === null) {
			const url = new URL(window.location.href);
			this.#urlParams = url.searchParams;
		}
		return this.#urlParams;
	}

	#getDomainFromUrl(input) {
		if (input == "") return "direct";
		const url = new URL(input);
		return url.hostname;
	}

	#getReferralDomain() {
		if (this.#referralDomain === null) {
			this.#referralDomain = this.#getDomainFromUrl(document.referrer);
			if (this.#referralDomain == "id.nathab.com") {
				this.#referralDomain = "";
			}
		}
		return this.#referralDomain;
	}

	// this will ultimately go away
	#getUTMZ() {
		if (this.#utmz === null) {
			this.#utmz = false;
			const utmz = unescape(this.#getCookie("__utmz"));
			if (utmz == "" || utmz == "false") return this.#utmz;
			const expiration = parseInt(utmz.match(/^\d+\.(\d+)/).pop()) + 30 * 24 * 3600;
			if (parseInt(Date.now() / 1000) > expiration) return this.#utmz;
			const pattern = new RegExp("utm[^=]+", "g"),
				results = {},
				keyMap = {
					utmccn: "utm_campaign",
					utmcct: "utm_content",
					utmcmd: "utm_medium",
					utmcsr: "utm_source",
					utmctr: "utm_term"
				};
			let match;
			while ((match = pattern.exec(utmz)) !== null) {
				results[keyMap[match[0]]] = utmz.match(new RegExp(match[0] + "=([^|]*)"))[1];
			}
			if (Object.keys(results).length > 0) {
				const gclid = this.#getCookie("gclid");
				if (gclid) results["gclid"] = gclid;
				const msclkid = this.#getCookie("msclkid");
				if (msclkid) results["msclkid"] = msclkid;
				const fbclid = this.#getCookie("fbclid");
				if (fbclid) results["fbclid"] = fbclid;
				results["referring_website"] = this.#getCookie("referring-website");
				this.#utmz = results;
			}
		}
		return this.#utmz;
	}

	#addClickIds(o) {
		const params = this.#getURLParameters();
		const clickParamNames = ["gclid", "msclkid", "fbclid"];
		for (let param of clickParamNames) {
			if (params.has(param)) {
				o[param] = params.get(param);
			}
		}
	}

	#getUTM() {
		if (this.#utms === null) {
			this.#utms = false;
			const params = this.#getURLParameters();
			const utmParams = {};
			const paramNames = ["utm_medium", "utm_source", "utm_campaign", "utm_content", "utm_term"];
			for (let param of paramNames) {
				if (params.has(param)) {
					utmParams[param] = params.get(param);
				}
			}
			if (Object.keys(utmParams).length > 0) {
				utmParams["referring_website"] = this.#getReferralDomain();
				this.#addClickIds(utmParams);
				this.#utms = utmParams;
			}
		}
		return this.#utms;
	}

	#clearExternal() {
		const d = new Date();
		d.setTime(d.getTime() - 1);
		document.cookie = "__utmz=;expires=" + d.toUTCString() + ";path=/;domain=.nathab.com";
		document.cookie = "gclid=;expires=" + d.toUTCString() + ";path=/;domain=.nathab.com";
		document.cookie = "fbclid=;expires=" + d.toUTCString() + ";path=/;domain=.nathab.com";
		document.cookie = "msclkid=;expires=" + d.toUTCString() + ";path=/;domain=.nathab.com";
		document.cookie = "referring-website=;expires=" + d.toUTCString() + ";path=/;domain=.nathab.com";
	}

	get() {
		return JSON.parse(decodeURIComponent(unescape(this.#getCookie("_source_information"))));
	}

	clear() {
		const d = new Date();
		d.setTime(d.getTime() - 1);
		document.cookie = "_source_information=;expires=" + d.toUTCString() + ";path=/";
		this.#clearExternal();
	}

	getCurrentDomain() {
		return this.#getDomainFromUrl(window.location.href);
	}

	getMarketo() {
		const source = this.get();
		return {
			utmsource: source.utm_source,
			utmmedium: source.utm_medium,
			utmcampaign: source.utm_campaign,
			utmterm: source.utm_term,
			utmcontent: source.utm_content,
			gclid: source.gclid,
			fbclid: source.fbclid,
			msclkid: source.msclkid,
			referringWebsite: source.referring_website,
			requestedAt: window.location.href
		};
	}

	constructor() {
		// if we have UTM params, always reset our cookie
		if (this.#getUTM()) {
			return this.#setCookie(this.#getUTM());
		}

		// if we have an organic visit, always reset our cookie
		if (this.#getOrganicSource()) {
			//utmcsr=bing|utmccn=(organic)|utmcmd=organic|utmctr=(not%20provided)
			const utms = {
				utm_source: this.#getOrganicSource(),
				utm_medium: "organic",
				utm_campaign: "(organic)",
				utm_term: "(not provided)",
				referring_website: this.#getReferralDomain()
			};
			this.#addClickIds(utms);
			return this.#setCookie(utms);
		}

		// if we have a referral, always reset our cookie
		// utmcsr=(direct)|utmccn=(direct)|utmcmd=(none)
		if (this.#isReferral()) {
			const utms = {
				utm_source: this.#getReferralDomain(),
				utm_medium: "referral",
				utm_campaign: "(referral)",
				referring_website: this.#getReferralDomain()
			};
			this.#addClickIds(utms);
			return this.#setCookie(utms);
		}

		if (this.#getUTMZ()) {
			return this.#setCookie(this.#getUTMZ());
		}

		// attempt to read source information our of the cookie
		// if we captured a new lead source or have one stored, just bail out
		if (this.get()) return true;

		// direct
		if (this.#isDirect()) {
			//utmcsr=(direct)|utmccn=(direct)|utmcmd=(none)
			const utms = {
				utm_source: "(direct)",
				utm_medium: "(none)",
				utm_campaign: "(direct)",
				referring_website: "direct"
			};
			return this.#setCookie(utms);
		}
	}
}
